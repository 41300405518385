import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Badge } from '@opengov/component-library/capital';

import opengovIcon from '../../../images/OpenGov-Icon-White.png';
import { LeftNav } from '../LeftNav';
import { Search } from '../Search';

import styles from './WebsiteHeader.scss';

const WebsiteHeader = props => {
  const [menuOpen, setMenuOpen] = useState(false);

  return (
    <header className={styles.header}>
      <img src={opengovIcon} className={styles.logo} alt="OpenGov Icon" />
      <Link to="/capital" className={styles.siteTitle}>
        <strong>Capital</strong> Design System
      </Link>
      <button aria-label="Open menu" className={`${styles.menuToggle} ${menuOpen ? styles.open : null}`} onClick={() => setMenuOpen(!menuOpen)}>
        <div className={styles.bar1}></div>
        <div className={styles.bar2}></div>
        <div className={styles.bar3}></div>
      </button>
      <div className={`${styles.navContainer}  ${menuOpen ? styles.open : null}`}>
        <nav className={styles.primaryNav}>
          <ul>
            <li className={props.activeTab === 'overview' ? styles.activeTab : null}>
              <Link to="/capital/overview">Overview</Link>
            </li>
            <li className={props.activeTab === 'styles' ? styles.activeTab : null}>
              <Link to="/capital/styles">Styles</Link>
            </li>
            <li className={props.activeTab === 'components' ? styles.activeTab : null}>
            <Link to="/capital/components">Components</Link>
            </li>
            <li className={props.activeTab === 'patterns' ? styles.activeTab : null}>
            <span className={styles.disabled}>Patterns&nbsp;&nbsp;<Badge text="Coming soon" /></span>
            </li>
            <Search />
          </ul>
        </nav>
        <LeftNav variant="header" activeTab={props.activeTab} />
      </div>
    </header>
  );
};

WebsiteHeader.propTypes = {
  siteTitle: PropTypes.string,
  activeTab: PropTypes.oneOf(['overview', 'styles', 'components', 'patterns'])
};

WebsiteHeader.defaultProps = {
  siteTitle: ``
};

export { WebsiteHeader };
