import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Icon, Input, InputGroup, Popover, PopoverTrigger, PopoverContent } from '@opengov/component-library/capital';

import navigationOverview from '../../data/navigation/overview.json';
import navigationStyles from '../../data/navigation/styles.json';
import navigationComponents from '../../data/navigation/components.json';
import navigationPatterns from '../../data/navigation/patterns.json';

import styles from './Search.scss';

export const Search = props => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showSearch, setShowSearch] = useState(false);

  // const findMatchedLinks = (section) => {
  //   navigation[activeTab].sections.map(
  // }

  // const getSearchResults = () => {
  //   if (searchTerm.length >= 2) {
  //     return null;
  //   }
  // }
  //
  // const toggleShowSearch = () => {
  //   setShowSearch(true);
  // }
  //
  // return (
  //   <>
  //     <button className={styles.headerButton} onClick={toggleShowSearch}>
  //       <Icon icon="action-search" />
  //     </button>
  //     <div className={styles.searchInput} style={{ display: showSearch ? 'block' : 'none' }}>
  //     </div>
  //     <div className={styles.searchResults}>
  //       <Input.Text value={searchTerm} onChange={setSearchTerm} />
  //       test
  //     </div>
  //     <div className={styles.searchOverlay} />
  //   </>
  // );

  return null;
}
