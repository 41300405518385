/* tslint:disable:no-default-export no-import-side-effect */

import PropTypes from 'prop-types';
import React, {useRef, useState} from 'react';
import { Icon } from '@opengov/component-library/capital';

import SEO from '../../seo';

import { LeftNav } from '../LeftNav';
import { WebsiteFooter } from '../WebsiteFooter';
import { WebsiteHeader } from '../WebsiteHeader';

import './capital-base.scss';
import styles from './Layout.scss';

const LayoutBase = (props) => {
  const [showScroll, setShowScroll] = useState(false)

  const scrollContainer = useRef(null);

  const onScroll = () => {
    if (document.getElementById("main").scrollTop > 100) {
      setShowScroll(true)
    } else if (document.getElementById("main").scrollTop < 100) {
      setShowScroll(false)
    }
  }

  const scrollToTop = () => {
    document.getElementById("main").scrollTop = 0
  }

  return (
    <>
    <SEO title={props.title} />
    <div className={styles.bodyWrapper}>
      <WebsiteHeader activeTab={props.activeTab} />
      <LeftNav activeTab={props.activeTab} />
      <main className={styles.mainCapital} id="main" onScroll={onScroll}>
        {props.children}
        {props.displayFooter ? <WebsiteFooter /> : null}
        <button className={styles.backToTop} onClick={scrollToTop} style={{display: showScroll ? 'block' : 'none'}}>
          <Icon icon="arrow-chevron-up" ariaLabel="Back to Top" /> Back to top
        </button>
      </main>
    </div>
    </>
  );
};

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
  activeTab: PropTypes.string.isRequired,
  displayFooter: PropTypes.bool,
  title: PropTypes.string
};

LayoutBase.defaultProps = {
  displayFooter: true
};

export default LayoutBase;
