import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/website/src/components/capital/Layouts/LayoutMDX.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Introduction to what this document is`}</em></p>
    <h2>{`React Component Library`}</h2>
    <p><em parentName="p">{`What is the component library, where does it live, how does it work`}</em></p>
    <ul>
      <li parentName="ul">{`Packages as one Library`}</li>
      <li parentName="ul">{`Built using CSS modules`}</li>
      <li parentName="ul">{`Components and Util functions`}</li>
      <li parentName="ul">{`Flexibility in use`}</li>
    </ul>
    <h2>{`Stylesheet`}</h2>
    <p><em parentName="p">{`What is the stylesheet`}</em></p>
    <ul>
      <li parentName="ul">{`For use by applications not built on React`}</li>
      <li parentName="ul">{`Only provides styles`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      