import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { Form, Input } from '@opengov/component-library/capital';

import navigationOverview from '../../../data/navigation/overview.json';
import navigationStyles from '../../../data/navigation/styles.json';
import navigationComponents from '../../../data/navigation/components.json';
import navigationPatterns from '../../../data/navigation/patterns.json';

import styles from './LeftNav.scss';

const navigation = {
  "overview": navigationOverview,
  "styles": navigationStyles,
  "components": navigationComponents,
  "patterns": navigationPatterns
};

const LeftNav = ({
  activeTab = 'overview',
  variant = ''
}) => {
  const [navFilter, setNavFilter] = useState("");

  const renderNavItems = section => {
    return section.links.map(item => {
      if (navFilter === "" || item.title.toLowerCase().indexOf(navFilter.toLowerCase()) > -1) {
        return (
          <li key={item.url}>
            <Link partiallyActive={true} activeClassName={styles.activePage} to={item.url}>
              {item.title}
            </Link>
          </li>
        );
      } else {
        return null;
      }
    });
  };

  const navigationMenuJSX = [];
  navigation[activeTab].sections.map(section => {
    if (section.package) {
      navigationMenuJSX.push(
        <li key={section.package} className={styles.package}>
          {section.package}
        </li>
      );
    } else if (section.title) {
      navigationMenuJSX.push(
        <li key={section.title} className={styles.section}>
          {section.title}
        </li>
      );
    }

    if (section.links) {
      navigationMenuJSX.push(renderNavItems(section));
    }

    return null;
  });

  return (
    <nav aria-label="Side navigation" className={variant === 'header' ? styles.secondaryNavHeader : styles.secondaryNav}>
      <Form.Item label="Filter menu">
        <Input.Text onChange={setNavFilter} placeholder="Filter" />
      </Form.Item>
      <ul>
        {navigationMenuJSX}
      </ul>
    </nav>
  );
};

LeftNav.propTypes = {
  activeTab: PropTypes.string,
  variant: PropTypes.string
};

export { LeftNav };
